import {
  cases,
  community,
  country,
  expert,
  forum,
  knowledgeCategory,
  subspeciality,
  caseItem,
  repost,
  members,
  speciality,
} from "../../js/path";
import _ from "lodash";

export default {
  data() {
    return {
      status: false,
      formwizard: true,
      table_header: [],
      case_id: null,
      images: [],
      videos: [],
      image_name_indonesia: [],
      knowledgeCategories: [],
      subspecialities: [],
      allCountry: [],
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "title",
          label: "Case Title",
        },
        {
          key: "link_id",
          label: "Link Id",
        },
        {
          key: "question_type",
          label: "Question Type",
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "repost",
        },
        {
          key: "doctor_approval",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      filter: null,
      pageNo: null,
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      filterOn: [],
      currentPage: 1,
      case_question_options: [
        {
          text: "MCQ",
          value: "mcq",
        },
        {
          text: "Comments",
          value: "comments",
        },
      ],
      question_options: [
        {
          text: "MCQ",
          value: "mcq",
        },
        {
          text: "Discussion Forum",
          value: "discussion_forum",
        },
      ],
      type_options: [
        {
          text: "None",
          value: "none",
        },
        {
          text: 1,
          value: 0,
        },
        {
          text: 2,
          value: 1,
        },
        {
          text: 3,
          value: 2,
        },
        {
          text: 4,
          value: 3,
        },
      ],
      oldForm: {},
      form: {
        card_image_show: "",
        member_tagging: null,
        translation: {
          indonesia: {
            title: "",
            description: "",
            meta_title: "",
            meta_desc: "",
            meta_keywords: "",
          },
        },
        title: "",
        description: "",
        question_type: "",
        view_multiplication_factor: 0,
        meta_title: "",
        meta_desc: "",
        meta_keywords: "",
        community_selected: [],
        country: [],
        tags: [],
        knowledge_category: [],
        sub_specialities: [],
        partner_division_id: "",
        expert_id: "",
        correct_option: "",
        is_brand_material: 0,
        addMore: [
          {
            type: "mcq",
            question: "",
            correct_option: null,
            answer_details: "",
            show_answer_at: null,
            option: [
              {
                key: 0,
                value: "",
              },
            ],
          },
        ],
        url_link: "",
      },
      community: [],
      allCommunity: [],
      forum: [],
      expert: [],
      showRepostModal: false,
      modalItem: null,
      memberList: [],
      doctorPostType: [
        {
          text: "Show forum image [front] and member image [back]",
          value: 1,
        },
        {
          text: "Show member image [front] and forum image [back]",
          value: 2,
        },
        {
          text: "Show only forum image",
          value: 3,
        },
        {
          text: "Show only member image",
          value: 4,
        },
      ],
      member_speciality_id: "",
      member_speciality: "",
      visible_on_doctor_profile: 0
    };
  },
  methods: {
    isEmpty(value) {
      return Array.isArray(value) && value.length === 0;
    },
    removeForumOption() {
      this.form.partner_division_id = "";
    },
    removeExpertOption() {
      this.form.expert_id = "";
    },
    fetchMemberList: _.debounce(async function (query) {
      this.$store.commit("loader/updateStatus", false);
      this.memberList = [];
      if (query != "" && query != null) {
        this.$store.commit("loader/updateStatus", true);
        const url = members.getMembers;
        const data = await this.getRequest(url + "?search=" + query);
        if (data.status) {
          this.memberList = data.response.data.map((member) => ({
            member_id: member.id,
            fnameAndLname: `${member.fname} ${member.lname} - ${member.mobile_number}`,
          }));
        }
      }
      this.$store.commit("loader/updateStatus", false);
    }, 1000),
    async fetchSingleMember(id) {
      const url = members.getMembers;
      const data = await this.getRequest(url + "/" + id);
      if (data.status) {
        this.form.member_tagging = {
          member_id: data.response.id,
          fnameAndLname: `${data.response.fname} ${data.response.lname} - ${data.response.mobile_number}`,
          city: data.response.city,
        };
        this.member_speciality_id = data.response.speciality_id;
        if (this.member_speciality_id) {
          this.fetchSingleSpeciality();
        }
      }
    },
    async fetchSingleSpeciality() {
      if (this.member_speciality_id) {
        const url = speciality.specialityUrl;
        const data = await this.getRequest(
          url + "/" + this.member_speciality_id
        );
        if (data.status) {
          this.member_speciality = data.response.title;
        }
      }
    },
    openRepostModal(item) {
      console.log(item, "item");
      this.modalItem = item.id;
      this.showRepostModal = true;
    },
    closeRepostModal() {
      this.showRepostModal = false;
    },
    async handleRepostSubmit() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let dataAppend = new FormData();
        dataAppend.append("content_type", "case");
        dataAppend.append("content_id", this.modalItem);
        let url = repost;
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.response.message
              ? data.response.message
              : "Updated successfully",
          });
        } else {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Something went wrong",
          });
        }
        this.$nextTick(() => {
          this.$bvModal.hide("bvRepostModal");
        });
      } catch (err) {
        console.log(err);
        this.$nextTick(() => {
          this.$bvModal.hide("bvRepostModal");
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    slugify() {
      this.newsletter_title = this.form.title;
      if (this.newsletter_title) {
        const slug_generate = this.newsletter_title
          .toString()
          .trim()
          .toLowerCase()
          .replace(/\s+/g, "-")
          .replace(/[^\w-]+/g, "")
          .replace(/--+/g, "-")
          .replace(/^-+/, "")
          .replace(/-+$/, "");
        this.form.url_link = slug_generate;
      } else {
        this.form.url_link = null;
      }
    },
    async downloadZip() {
      this.$store.commit("loader/updateStatus", true);
      const data = await this.postRequest(
        cases.caseUrl + "/download-zip/" + this.case_id
      );
      this.$store.commit("loader/updateStatus", false);
      window.location.href = data.response;
    },
    questionType() {
      if (this.form.question_type == "comments") {
        this.formwizard = false;
      } else {
        this.formwizard = true;
      }
    },
    addOption(key) {
      let length = this.form.addMore[key].option.length;
      if (length <= 3) {
        this.form.addMore[key].option.push({
          key: length,
          value: "",
        });
      }
    },
    removeOption(index, key) {
      if (index == 0) return;
      this.form.addMore[key].option.splice(index, 1);
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.currentPage = 1;
          this.fetchData(this.activeTab);
        }
      } else if (this.filter.length == 0) {
        this.currentPage = 1;
        this.fetchData(this.activeTab);
      }
    },
    searchFor() {
      if (this.filter.length > 0) {
        this.currentPage = 1;
        this.fetchData(this.activeTab);
      } else {
        this.currentPage = 1;
        this.fetchData(this.activeTab);
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData(txt) {
      let url = null;
      if (txt == "trash") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url = cases.caseUrl + "?filter=" + txt + "&search=" + this.filter;
        } else if (this.filter != null && this.filter != "") {
          url = url = cases.caseUrl + "?filter=" + txt + this.params;
        } else {
          url = cases.caseUrl + "?filter=" + txt;
        }
      } else if (txt == "active") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url = cases.caseUrl + "?filter=" + txt + "&search=" + this.filter;
        } else {
          url = cases.caseUrl + "?filter=" + txt;
        }
      } else if (txt == "all") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url = cases.caseUrl + "?filter=" + txt + "&search=" + this.filter;
        } else {
          url = cases.caseUrl + "?filter=" + txt;
        }
      }
      url += this.params;
      this.getUrl();
      this.$store.commit("loader/updateStatus", true);
      try {
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
      this.key += 1;
    },
    readFile(e, txt) {
      if (txt == "image") {
        this.image = e.target.files[0];
        const MAX_FILE_SIZE = 20480;
        const fileSizeInKB = this.image.size / 1024;
        if (fileSizeInKB > MAX_FILE_SIZE) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Image cannot be larger than 20 MB",
          });
          this.$refs.case_images.reset();
        } else {
          var selectedFiles = e.target.files;
          for (let i = 0; i < selectedFiles.length; i++) {
            this.images.push({
              id: this.id ? this.id : "",
              image: URL.createObjectURL(selectedFiles[i]),
              type: "image",
            });
          }
        }
      }
      if (txt == "video") {
        this.video = e.target.files[0];
        const MAX_FILE_SIZE = 20480;
        const fileSizeInKB = this.video.size / 1024;
        if (fileSizeInKB > MAX_FILE_SIZE) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Video cannot be larger than 20 MB",
          });
          this.$refs.case_videos.reset();
        } else {
          var selectedVideos = e.target.files;
          for (let i = 0; i < selectedVideos.length; i++) {
            this.videos.push({
              id: this.id ? this.id : "",
              type: "video",
              video: URL.createObjectURL(selectedVideos[i]),
            });
          }
        }
      } else if (txt == "indonesia_image") {
        this.indonesia_image = e.target.files[0];
        if (this.indonesia_image.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.indonesia_item_image.reset();
        }
      }
    },
    addmore() {
      const options = [
        {
          key: 0,
          value: "",
        },
      ];
      this.form.addMore.push({
        type: "mcq",
        question: "",
        correct_option: "",
        option: options,
        answer_details: null,
        show_answer_at: null,
      });
      this.setSlide();
    },
    deleteRow(index) {
      this.form.addMore.splice(index, 1);
    },
    setSlide() {
      this.slide = this.slide + 1;
    },
    async fetchCase(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = cases.caseUrl + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          if (responseData.created_by_member_id) {
            this.form.member_tagging = this.fetchSingleMember(
              responseData.created_by_member_id
            );
          }
          if (
            responseData.card_image_show == 0 &&
            responseData.created_by_member_id
          ) {
            this.form.card_image_show = 4;
          } else {
            this.form.card_image_show = responseData.card_image_show;
          }
          if (responseData.title != null && responseData != "") {
            this.form.title = responseData.title;
          }
          if (responseData.meta_desc != null && responseData.meta_desc != "") {
            this.form.meta_desc = responseData.meta_desc;
          }
          if (
            responseData.meta_keywords != null &&
            responseData.meta_keywords != null
          ) {
            this.form.meta_keywords = responseData.meta_keywords;
          }
          if (
            responseData.meta_title != "" &&
            responseData.meta_title != null
          ) {
            this.form.meta_title = responseData.meta_title;
          }

          if (
            responseData.view_multiplication_factor != "" &&
            responseData.view_multiplication_factor != null
          ) {
            if (responseData.view_multiplication_factor == 0) {
              this.form.view_multiplication_factor = 1;
            } else {
              this.form.view_multiplication_factor =
                responseData.view_multiplication_factor;
            }
          }

          if (responseData.visible_on_doctor_profile) {
            this.form.visible_on_doctor_profile = true;
          }

          if (
            responseData.description != null &&
            responseData.description != ""
          ) {
            this.form.description = responseData.description;
          }

          this.form.question_type = responseData.question_type;

          if (
            responseData.partner_division_id != "" &&
            responseData.partner_division_id != null
          ) {
            this.form.partner_division_id = this.fetchSingleForum(
              responseData.partner_division_id
            );
          }

          if (responseData.expert_id != "" && responseData.expert_id != null) {
            this.form.expert_id = this.fetchSingleExpert(
              responseData.expert_id
            );
          }

          this.case_id = responseData.id;

          if (
            responseData.sub_specialities != null &&
            responseData.sub_specialities != ""
          ) {
            this.form.sub_specialities = this.subspecialities.filter((item) => {
              if (responseData.sub_specialities.includes(item.id)) {
                return item;
              }
            });
          }

          if (responseData.community != null && responseData.community != "") {
            this.form.community_selected = responseData.community.map((c) => {
              return {
                id: c.community_id,
                title: c.name,
              };
            });
          }

          if (
            responseData.knowledge_category != null &&
            responseData.knowledge_category != ""
          ) {
            responseData.knowledge_category.map((cat) => {
              this.form.knowledge_category.push(
                ...this.knowledgeCategories.filter((item) => {
                  return cat.knowledge_category_id == item.id;
                })
              );
            });
          }

          if (responseData.country != null && responseData.country != "") {
            responseData.country.map((item) => {
              this.fetchSingleCountry(item);
            });
          }

          if (responseData.tags != null && responseData.tags != "") {
            this.form.tags = responseData.tags;
          }

          if (responseData.questions != "" && responseData.questions != null) {
            let ques = responseData.questions.map((item) => {
              delete item.id;
              delete item.member_questions;
              delete item.answer;
              return item;
            });
            this.form.addMore = ques;
          }

          if (responseData.case_item != "" && responseData.case_item != null) {
            responseData.case_item.map((item) => {
              if (item.type == "video") {
                this.videos.push({
                  id: item.id ? item.id : "",
                  image: item.image ? item.image : "",
                  type: item.type ? item.type : "video",
                });
              } else if (item.type == "image") {
                this.images.push({
                  id: item.id ? item.id : "",
                  image: item.image ? item.image : "",
                  type: item.type ? item.type : "image",
                });
              }
            });
          }
          if (
            responseData.translation != "" &&
            responseData.translation != null
          ) {
            this.form.translation = responseData.translation;
            this.image_name_indonesia = responseData.translation.indonesia.image
              ? responseData.translation.indonesia.image
              : "";
          }
          if (responseData.url_link != "" && responseData.url_link != null) {
            this.form.url_link = responseData.url_link;
          }
          // if (
          //   responseData.member_tagging != "" &&
          //   responseData.member_tagging != null
          // ) {
          //   responseData.member_tagging.map((item) => {
          //     this.fetchSingleMember(item.member_id);
          //   });
          // }

          if (responseData.brand_material) {
            this.form.is_brand_material = true;
          }
        }
        this.oldForm = _.clone(this.form);
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = cases.statusUpdate;
        const data = await this.postRequest(url, {
          is_active: !this.tableData.data[index].is_active,
          id: id,
        });
        if (data.status) {
          this.tableData.data[index].is_active =
            !this.tableData.data[index].is_active;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async doctorApproval(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const url = cases.doctorApproval;
        const data = await this.postRequest(url, {
          action_id: id,
        });
        if (data.status) {
          console.log("in if");
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.key += 1;
          this.fetchData(this.activeTab);
        } else {
          console.log("in else");
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: data.data.message ? data.data.message : "Please try again!",
          });
        }
      } catch (err) {
        const errStatus = err?.data?.status;
        console.log(errStatus, 'err status');
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    getUrl() {
      var url = new URL(window.location);
      if (this.activeTab == null || this.activeTab == "") {
        this.activeTab = "all";
      }
      url.searchParams.has("filter")
        ? url.searchParams.set("filter", this.activeTab)
        : url.searchParams.append("filter", this.activeTab);
      if (this.filter != null && this.filter != "") {
        url.searchParams.has("search")
          ? url.searchParams.set("search", this.filter)
          : url.searchParams.append("search", this.filter);
      } else {
        url.searchParams.delete("search");
      }
      if (this.currentPage != null && this.currentPage != "") {
        url.searchParams.has("page")
          ? url.searchParams.set("page", this.currentPage)
          : url.searchParams.append("page", this.currentPage);
      } else {
        url.searchParams.set("page", 1);
      }
      url.search = url.searchParams;
      url = url.toString();
      history.pushState({}, null, url);
    },
    async updateCommentStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.findIndex((e) => e.id == id);
        const url = cases.caseCommentStatusUpdate;
        const data = await this.postRequest(url, {
          is_approved: !this.tableData[index].is_approved,
          id: id,
        });
        if (data.status) {
          this.tableData[index].is_approved =
            !this.tableData[index].is_approved;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async deleteCase(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = cases.caseUrl + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async deleteCaseMCQ(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.findIndex((e) => e.id === id);
        const url = cases.deleteCaseMCQ + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async restoreCase(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = cases.caseUrl + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    tabActive() {
      if (this.activeTab == "trash") {
        if (this.table_header.includes("delete")) {
          let index = this.fields.findIndex((item) => item.key == "delete");
          this.fields[index] = {
            key: "restore",
          };
          let table_index = this.table_header.findIndex(
            (item) => item == "delete"
          );
          this.table_header[table_index] = "restore";
        } else {
          this.table_header.push("restore");
          this.fields.push({
            key: "restore",
          });
        }

        if (!this.can("restore-case")) {
          let index = this.fields.findIndex((item) => item.key == "restore");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "restore"
            );
            this.table_header.splice(table_index, 1);
          }
        }

        let index = this.fields.findIndex((item) => item.key == "edit");
        if (index !== -1) {
          this.fields.splice(index, 1);
          let table_index = this.table_header.findIndex(
            (item) => item == "edit"
          );
          this.table_header.splice(table_index, 1);
        }
      } else {
        if (this.table_header.includes("restore")) {
          if (!this.can("edit-case")) {
            this.fields[6] = {
              key: "delete",
            };
          } else {
            this.fields[7] = {
              key: "delete",
            };
          }

          let table_index = this.table_header.findIndex(
            (item) => item == "restore"
          );
          this.table_header[table_index] = "delete";
        }

        if (!this.can("edit-case")) {
          let index = this.fields.findIndex((item) => item.key == "edit");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "edit"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (!this.table_header.includes("edit")) {
            this.table_header.push("edit");
            this.fields[6] = {
              key: "edit",
            };
          }
        }

        if (!this.can("delete-case")) {
          let index = this.fields.findIndex((item) => item.key == "delete");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "delete"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (!this.table_header.includes("delete")) {
            this.table_header.push("delete");
            this.fields[7] = {
              key: "delete",
            };
          }
        }
      }
    },
    async fetchAllCommunity() {
      const url = community.fetchAllActiveCommunity;
      const data = await this.getRequest(url);
      if (data.status) {
        this.form.community_selected = data.response.data;
      }
    },
    async fetchCommunity(query) {
      const url = community.fetchAllActiveCommunity;
      const data = await this.getRequest(url + "&search=" + query);
      if (data.status) {
        this.community = data.response.data;
      }
    },
    async fetchAllCountry(query) {
      const url = country.countryUrl;
      const data = await this.getRequest(url + "?search=" + query);
      if (data.status) {
        this.allCountry = data.response.data;
      }
    },
    async fetchSingleCountry(name) {
      const url = country.countryUrl;
      const data = await this.getRequest(url + "/" + name + "?country_name=1");
      if (data.status) {
        this.form.country.push(data.response);
      }
    },
    async fetchKnowledgeCategory() {
      const url = knowledgeCategory.fetchKnowledgeCategory;
      const data = await this.getRequest(url);
      if (data.status) {
        this.knowledgeCategories = data.response.data;
      }
    },
    async fetchSubSpeciality() {
      const url = subspeciality.fetchSubSpeciality;
      const data = await this.getRequest(url);
      if (data.status) {
        this.subspecialities = data.response.data;
      }
    },
    async fetchForum(query) {
      const url = forum.fetchAllActiveForum;
      const data = await this.getRequest(url + "&search=" + query);
      if (data.status) {
        this.forum = data.response.data;
      }
    },
    async fetchSingleForum(id) {
      const url = forum.forumUrl;
      const data = await this.getRequest(url + "/" + id);
      if (data.status) {
        this.form.partner_division_id = data.response;
      }
    },
    async fetchExpert(query) {
      const url = expert.expertVisibleInCases;
      const data = await this.getRequest(url + "&search=" + query);
      if (data.status) {
        this.expert = data.response.data;
      }
    },
    async fetchSingleExpert(id) {
      const url = expert.expertUrl;
      const data = await this.getRequest(url + "/" + id);
      if (data.status) {
        this.form.expert_id = data.response;
      }
    },
    async deleteImage(itemId, index, type) {
      let res = await this.$bvModal.msgBoxConfirm(
        "Are you sure you want to delete ?",
        {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "Yes, Delete it!",
          headerClass: "px-2 pt-2 pb-0 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        }
      );
      if (res == true) {
        const url = caseItem.deleteCaseItem;
        const data = await this.postRequest(url + "/" + itemId, {
          _method: "DELETE",
        });
        if (data.status) {
          console.log(index, "index");
          if (type == "video") {
            this.videos.splice(index, 1);
          } else if (type == "image") {
            this.images.splice(index, 1);
          }
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      }
    },
    async submitData() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = cases.caseUrl;
        if (this.$route.name == "edit-case") {
          url = cases.caseUrl + "/" + this.case_id;
        }
        let dataAppend = new FormData();
        let currentIndex = 0;
        if (this.$refs.case_images.files.length > 0) {
          for (
            let index = 0;
            index < this.$refs.case_images.files.length;
            index++
          ) {
            dataAppend.append(
              "images[" + currentIndex + "]",
              this.$refs.case_images.files[index]
            );
            currentIndex++;
          }
        }
        if (this.$refs.case_videos.files.length > 0) {
          for (
            let index = 0;
            index < this.$refs.case_videos.files.length;
            index++
          ) {
            dataAppend.append(
              "images[" + currentIndex + "]",
              this.$refs.case_videos.files[index]
            );
            currentIndex++;
          }
        }
        if (this.form.visible_on_doctor_profile) {
            dataAppend.append("visible_on_doctor_profile", 1);
        } else {
            dataAppend.append("visible_on_doctor_profile", 0);
        }
        console.log(
          this.$refs.case_images.files,
          "img ref",
          this.$refs.case_videos.files,
          "video ref"
        );
        if (this.$refs.indonesia_item_image.files.length > 0) {
          for (
            let index = 0;
            index < this.$refs.indonesia_item_image.files.length;
            index++
          ) {
            dataAppend.append(
              "image_name_indonesia[" + index + "]",
              this.$refs.indonesia_item_image.files[index]
            );
          }
        }
        if (this.form.member_tagging) {
          dataAppend.append(
            "member_tagging",
            this.form.member_tagging.member_id
          );
        }
        for (var key in this.form) {
          if (
            key != "option" &&
            key != "sub_specialities" &&
            key != "community_selected" &&
            key != "addMore" &&
            key != "country" &&
            key != "knowledge_category" &&
            key != "tags" &&
            key != "translation" &&
            key != "expert_id" &&
            key != "partner_division_id" &&
            key != "member_tagging" &&
            key != "card_image_show" &&
            key != "visible_on_doctor_profile" &&
            key != "brand_material"
          ) {
            dataAppend.append(key, this.form[key]);
          }
        }

        if (this.form.card_image_show === "") {
          dataAppend.append("card_image_show", 0);
        } else {
          dataAppend.append("card_image_show", this.form.card_image_show);
        }

        if (
          this.form.partner_division_id != "" &&
          this.form.partner_division_id != null
        ) {
          dataAppend.append(
            "partner_division_id",
            this.form.partner_division_id.id
          );
        }

        if (this.form.expert_id != "" && this.form.expert_id != null) {
          dataAppend.append("expert_id", this.form.expert_id.id);
        }

        const community_selected = this.form.community_selected.map(
          (key) => key.id
        );
        const sub_specialities = this.form.sub_specialities.map(
          (key) => key.id
        );
        const country = this.form.country.map((key) => key.name);
        const knowledge_category = this.form.knowledge_category.map(
          (key) => key.id
        );
        const tags = this.form.tags.map((key) => key);

        community_selected.forEach((c) =>
          dataAppend.append("community_selected[]", c)
        );
        sub_specialities.forEach((s) =>
          dataAppend.append("sub_specialities[]", s)
        );
        country.forEach((l) => dataAppend.append("country[]", l));
        knowledge_category.forEach((k) =>
          dataAppend.append("knowledge_categories[]", k)
        );
        tags.forEach((t) => dataAppend.append("tags[]", t));

        dataAppend.append("question_data", JSON.stringify(this.form.addMore));
        dataAppend.append("translation", JSON.stringify(this.form.translation));
        if (this.form.is_brand_material) {
          dataAppend.append("brand_material", 1);
        } else {
          dataAppend.append("brand_material", 0);
        }
        if (this.$route.name == "edit-case") {
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/case");
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus"),
          {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          };
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
  watch: {
    status(v) {
      if (v == 1) {
        this.fetchAllCommunity();
      } else {
        if (this.$route.name == "add-case") {
          this.form.community_selected = [];
        } else if (this.$route.name == "edit-case") {
          this.form.community_selected = this.oldForm.community_selected;
        }
      }
    },
    currentPage: {
      handler: function (value) {
        this.params = `&page=${value}`;
        this.fetchData(this.activeTab);
        this.pageNo = this.currentPage;
      },
    },
    activeTab(v) {
      if (v) this.tabActive();
    },
  },
  mounted() {
    if (this.$route.name == "add-case" || this.$route.name == "edit-case") {
      this.$store.commit("loader/updateStatus", true);
      this.fetchSubSpeciality(),
        this.fetchKnowledgeCategory(),
        this.$store.commit("loader/updateStatus", false);
      if (this.$route.name == "edit-case") {
        this.fetchCase(this.$route.params.id);
      }
    } else {
      if (this.$route.name == "case-question-mcq") {
        this.fetchCaseQuestionMCQ();
      } else {
        this.activeTab = "all";
        if (this.$route.query.filter) {
          this.activeTab = this.$route.query.filter;
        }
        if (this.$route.query.search) {
          this.filter = this.$route.query.search;
        }
        if (this.$route.query.page && this.$route.query.page != 1) {
          this.currentPage = parseInt(this.$route.query.page);
        } else {
          this.fetchData(this.activeTab);
        }
      }
    }
    this.fields.map((item) => {
      this.table_header.push(item.key);
    });
    this.tabActive();
  },
};
